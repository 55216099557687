import {
    ChangeDetectionStrategy,
    Component,
    effect,
    EventEmitter,
    inject,
    Input,
    OnInit,
    Output,
    signal,
} from '@angular/core';
import {
    AbstractControl,
    FormBuilder,
    FormControl,
    FormGroup,
    ReactiveFormsModule,
    ValidationErrors,
    ValidatorFn,
} from '@angular/forms';
import { distinctUntilChanged, map, Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { HdmuiAvatarComponent } from '@heidelberg/hdmui-angular';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { AvailableAssistantsUser, Machine, Role } from '@data-terminal/shared-models';

const EMPLOYEES_LIMIT = 5;
const MIN_INPUT_LENGTH = 1;

function unknownEmployeeValidator(availableEmployees: AvailableAssistantsUser[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (control.value.length && !availableEmployees.map(({ userId }) => userId).includes(control.value)) {
            return { unknownEmployee: { value: control.value } };
        }

        return null;
    };
}

function employeeAddedValidator(selectedEmployees: AvailableAssistantsUser[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (selectedEmployees.map(({ userId }) => userId).includes(control.value)) {
            return { employeeAdded: { value: control.value } };
        }

        return null;
    };
}

@UntilDestroy()
@Component({
    standalone: true,
    selector: 'data-terminal-manage-assistant-card',
    templateUrl: './manage-assistant-card.component.html',
    styleUrls: ['./manage-assistant-card.component.scss'],
    imports: [
        ReactiveFormsModule,
        MatIconModule,
        MatButtonModule,
        MatAutocompleteModule,
        MatInputModule,
        HdmuiAvatarComponent,
        MatCardModule,
        MatDividerModule,
        CommonModule,
        MatTooltipModule,
        TranslateModule,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageAssistantCardComponent implements OnInit {
    readonly #fb = inject(FormBuilder);

    public form!: FormGroup;
    public selectedEmployees = signal<AvailableAssistantsUser[]>([]);
    public employeesLimit = EMPLOYEES_LIMIT;

    public employeeSuggestions$!: Observable<AvailableAssistantsUser[]>;

    @Input()
    availableEmployees: AvailableAssistantsUser[] = [];

    @Input()
    workstation!: Machine;

    @Output()
    selectedEmployeesChange = new EventEmitter<AvailableAssistantsUser[]>();

    constructor() {
        effect(() => {
            this.selectedEmployeesChange.emit(this.selectedEmployees());
        });
    }

    ngOnInit(): void {
        this.initForm();
        this.initSelectedEmployees();
        this.listenForEmployeeSuggestions();
    }

    public addEmployee(): void {
        const limitReached = this.selectedEmployees().length >= this.employeesLimit;
        const employeeIdFC = this.form.get('employeeId');
        const employeeId = employeeIdFC?.value;
        const employee = this.availableEmployees.find(({ userId }) => userId === employeeId);
        const alreadySelected = this.selectedEmployees().find(({ userId }) => userId === employeeId);

        if (!alreadySelected && !limitReached && employee && employeeIdFC) {
            this.selectedEmployees.set([...this.selectedEmployees(), employee]);
            employeeIdFC.setValue('');
            employeeIdFC.markAsUntouched();
        }
    }

    public removeEmployee(id: string): void {
        this.selectedEmployees.set(
            this.selectedEmployees().filter((employee) => {
                return employee.userId !== id;
            })
        );
    }

    private initForm(): void {
        this.form = this.#fb.group({
            employeeId: this.#fb.control({ value: '', disabled: !this.availableEmployees.length }, [
                unknownEmployeeValidator(this.availableEmployees),
                employeeAddedValidator(this.selectedEmployees()),
            ]),
        });
    }

    private initSelectedEmployees(): void {
        this.selectedEmployees.set(
            this.workstation.signedOnUsers
                .filter(({ role }) => role === Role.ASSISTANT)
                .map((user) => ({
                    userId: user.userId,
                    familyName: user.lastName,
                    firstName: user.firstName,
                }))
        );
    }

    private listenForEmployeeSuggestions(): void {
        const employeeFC: FormControl = this.form.get('employeeId') as FormControl;

        if (employeeFC) {
            this.employeeSuggestions$ = employeeFC.valueChanges.pipe(
                untilDestroyed(this),
                distinctUntilChanged(),
                map((employeeInput: string) =>
                    employeeInput.length >= MIN_INPUT_LENGTH
                        ? this.availableEmployees
                              .filter((employee) =>
                                  [
                                      employee.userId.toLowerCase(),
                                      employee.firstName.toLowerCase(),
                                      employee.familyName.toLowerCase(),
                                  ].some((option) => option.includes(employeeInput.toLowerCase().trim()))
                              )
                              .filter(
                                  ({ userId }) =>
                                      !this.selectedEmployees()
                                          .map((selectEmployee) => selectEmployee.userId)
                                          .includes(userId)
                              )
                        : []
                )
            );
        }
    }
}
