import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import {
    AbstractControl,
    FormBuilder,
    FormGroup,
    ReactiveFormsModule,
    ValidationErrors,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChanged, interval, map } from 'rxjs';

import { Machine } from '@data-terminal/shared-models';
import { MINUTES_IN_HOUR } from '@data-terminal/utils';

const StartTimeRegex = /^([0-9]{2}):([0-9]{2})$/;

const START_TIME_REGEX_MATCH_LENGTH = 3;

function toTimestamp(value: string): number | undefined {
    const match = value.match(StartTimeRegex);

    if (match?.length === START_TIME_REGEX_MATCH_LENGTH) {
        const hours = Number(match[1]);
        const minutes = Number(match[2]);

        const date = new Date();
        date.setHours(hours, minutes, 0, 0);

        return date.getTime();
    }

    return undefined;
}

function maxStartTimeValidator(calculatedMinutes: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const match = (control.value as string).match(StartTimeRegex);

        let inputHours: number | undefined;
        let inputMinutes: number | undefined;

        if (match?.length === START_TIME_REGEX_MATCH_LENGTH) {
            inputHours = Number(match[1]);
            inputMinutes = Number(match[2]);
        }

        if (inputHours === undefined || inputMinutes === undefined) {
            return null;
        }

        if (inputHours * MINUTES_IN_HOUR + inputMinutes > calculatedMinutes) {
            return { maxStartTime: { value: true } };
        }

        return null;
    };
}

@UntilDestroy()
@Component({
    standalone: true,
    selector: 'data-terminal-sign-in-time-machine-card',
    styleUrls: ['sign-in-time-machine-card.component.scss'],
    templateUrl: 'sign-in-time-machine-card.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [TranslateModule, MatCardModule, MatInputModule, MatIconModule, ReactiveFormsModule],
})
export class SignInTimeMachineCardComponent implements OnInit {
    readonly #fb = inject(FormBuilder);

    @Input()
    machine!: Machine;

    @Output()
    timestampChange = new EventEmitter<number>();

    form!: FormGroup;

    readonly #basicValidators = [Validators.required, Validators.pattern(StartTimeRegex)];

    constructor() {
        this.form = this.#fb.group({
            time: ['', this.#basicValidators],
        });

        const timeFC = this.form.get('time');

        timeFC?.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
            const timestamp = toTimestamp(value);

            if (this.form.valid && timestamp) {
                this.timestampChange.emit(timestamp);
            } else {
                this.timestampChange.emit(undefined);
            }
        });

        interval(1000)
            .pipe(
                untilDestroyed(this),
                map(() => {
                    const nowHours = new Date().getHours();
                    const nowMinutes = new Date().getMinutes();
                    return nowHours * MINUTES_IN_HOUR + nowMinutes;
                }),
                distinctUntilChanged()
            )
            .subscribe((calculatedMinutes) => {
                if (timeFC) {
                    timeFC.setValidators([maxStartTimeValidator(calculatedMinutes), ...this.#basicValidators]);
                    timeFC.updateValueAndValidity();
                }
            });
    }

    ngOnInit(): void {
        const ONE_DIGIT_THRESHOLD = 9;
        const now = new Date();
        const hours = now.getHours();
        const minutes = now.getMinutes();

        const formattedHours = hours <= ONE_DIGIT_THRESHOLD ? `0${hours}` : `${hours}`;
        const formattedMinutes = minutes <= ONE_DIGIT_THRESHOLD ? `0${minutes}` : `${minutes}`;

        this.form.setValue({
            time: `${formattedHours}:${formattedMinutes}`,
        });
    }
}
