<div class="hdmui hdmui-container sign-in">
    <header>
        <mat-toolbar class="sign-in__header">
            <h2>
                @if (!startTimesStep && !actionsStep && assistantsStep) {
                    {{ 'DC.JOIN-OR-TAKEOVER.DIALOG.HEADING.CHOOSE_ASSISTANTS' | translate }}
                }
                @if (!startTimesStep && actionsStep && !assistantsStep) {
                    {{ 'DC.JOIN-OR-TAKEOVER.DIALOG.HEADING.CHOOSE_ACTION' | translate }}
                }
                @if (!startTimesStep && actionsStep && assistantsStep) {
                    {{ 'DC.JOIN-OR-TAKEOVER.DIALOG.HEADING.CHOOSE_ACTION_ASSISTANTS' | translate }}
                }
                @if (startTimesStep && !actionsStep && !assistantsStep) {
                    {{ 'DC.JOIN-OR-TAKEOVER.DIALOG.HEADING.CHOOSE_TIMES' | translate }}
                }
                @if (startTimesStep && !actionsStep && assistantsStep) {
                    {{ 'DC.JOIN-OR-TAKEOVER.DIALOG.HEADING.CHOOSE_TIMES_ASSISTANTS' | translate }}
                }
                @if (startTimesStep && actionsStep && !assistantsStep) {
                    {{ 'DC.JOIN-OR-TAKEOVER.DIALOG.HEADING.CHOOSE_TIMES_ACTION' | translate }}
                }
                @if (startTimesStep && actionsStep && assistantsStep) {
                    {{ 'DC.JOIN-OR-TAKEOVER.DIALOG.HEADING.CHOOSE_TIMES_ACTION_ASSISTANTS' | translate }}
                }
            </h2>
        </mat-toolbar>
    </header>

    <section class="hdmui-content sign-in__content">
        <mat-stepper [linear]="true" [class.header-visible]="(stepper?.steps?.length || 0) > 1">
            <ng-template matStepperIcon="edit">
                <mat-icon class="stepper-done__icon" svgIcon="hdmui:hook"></mat-icon>
            </ng-template>

            @if (timeModeMachines.length) {
                <mat-step
                    #startTimesStep
                    [label]="'DC.JOIN-OR-TAKEOVER.DIALOG.HEADING.TIMES' | translate"
                    [completed]="!hasInvalidStartTimeInputs()"
                >
                    <div class="hdmui-content">
                        <div class="step__description">
                            <p class="hdmui-m-0">
                                {{ 'DC.SIGN_IN_MACHINE_DIALOG.START_TIMES.DESCRIPTION' | translate }}
                            </p>
                        </div>
                        <div class="sign-in__cards hdmui-mt-4 hdmui-pb-4">
                            @for (machine of timeModeMachines; track machine) {
                                <data-terminal-sign-in-time-machine-card
                                    [machine]="machine"
                                    (timestampChange)="onSignInTimestampChange($event, machine)"
                                ></data-terminal-sign-in-time-machine-card>
                            }
                        </div>
                    </div>
                </mat-step>
            }

            @if (machinesWithOperator.length) {
                <mat-step #actionsStep [label]="'ACTIONS' | translate">
                    <div class="hdmui-content">
                        <div class="step__description">
                            <p class="hdmui-m-0">
                                {{ 'DC.JOIN-OR-TAKEOVER.DIALOG.HEADING.ACTIONS' | translate }}
                            </p>
                            <data-terminal-help-button>
                                <h2 class="hdmui-font-small hdmui-font-bold hdmui-m-0">
                                    {{ 'DC.JOIN-OR-TAKEOVER.DIALOG.JOIN.NAME' | translate }}
                                </h2>
                                <p class="hdmui-font-small hdmui-m-0 hdmui-mt-1">
                                    {{ 'DC.JOIN-OR-TAKEOVER.DIALOG.JOIN.EXPLANATION' | translate }}
                                </p>
                                <h2 class="hdmui-font-small hdmui-font-bold hdmui-m-0 hdmui-mt-4">
                                    {{ 'DC.JOIN-OR-TAKEOVER.DIALOG.TAKEOVER.NAME' | translate }}
                                </h2>
                                <p class="hdmui-font-small hdmui-m-0 hdmui-mt-1">
                                    {{ 'DC.JOIN-OR-TAKEOVER.DIALOG.TAKEOVER.EXPLANATION' | translate }}
                                </p>
                            </data-terminal-help-button>
                        </div>
                        <div class="sign-in__cards hdmui-mt-4 hdmui-pb-4">
                            @for (machine of machinesWithOperator; track machine; let i = $index) {
                                <mat-card appearance="outlined" class="sign-in-card hdmui-p-4">
                                    <div class="sign-in-card__header">
                                        <h1 class="hdmui-font-serif hdmui-m-0">
                                            {{ machine.machineName }}
                                        </h1>
                                        <h1 class="hdmui-font-bold hdmui-m-0">
                                            {{ machine.machineId }}
                                        </h1>
                                    </div>
                                    <mat-radio-group
                                        [name]="'login_type-' + i"
                                        labelPosition="before"
                                        (change)="onLoginTypeChange($event, machine)"
                                        [(ngModel)]="machine.loginType"
                                        [disabled]="machine.captureMode === CaptureMode.TIME"
                                    >
                                        <div>
                                            <mat-radio-button [name]="'login_type-' + i" [value]="LoginType.JOIN">
                                                {{ 'DC.JOIN-OR-TAKEOVER.DIALOG.JOIN.NAME' | translate }}
                                            </mat-radio-button>
                                        </div>
                                        <div>
                                            <mat-radio-button [name]="'login_type-' + i" [value]="LoginType.TAKE_OVER">
                                                {{ 'DC.JOIN-OR-TAKEOVER.DIALOG.TAKEOVER.NAME' | translate }}
                                            </mat-radio-button>
                                        </div>
                                    </mat-radio-group>
                                    <mat-divider class="hdmui-my-4"></mat-divider>
                                    <mat-checkbox
                                        [indeterminate]="false"
                                        labelPosition="before"
                                        [disabled]="
                                            machine.loginType !== LoginType.TAKE_OVER ||
                                            machine.captureMode === CaptureMode.TIME
                                        "
                                        [(ngModel)]="machine.withAssistants"
                                    >
                                        <span>{{ 'DC.SIGN_IN_MACHINE_DIALOG.WITH_ASSISTANTS' | translate }}</span>
                                    </mat-checkbox>
                                </mat-card>
                            }
                        </div>
                    </div>
                </mat-step>
            }

            @if (machinesWithAssistants.length) {
                <mat-step #assistantsStep [label]="'DC.JOIN-OR-TAKEOVER.DIALOG.ASSISTANTS' | translate">
                    <div class="hdmui-content">
                        <div class="step__description">
                            <p class="hdmui-m-0">
                                {{ 'DC.JOIN-OR-TAKEOVER.DIALOG.HEADING.ASSISTANTS' | translate }}
                            </p>
                            <data-terminal-help-button>
                                <h2 class="hdmui-font-small hdmui-font-bold hdmui-m-0">
                                    {{ 'DC.JOIN-OR-TAKEOVER.DIALOG.ADD_ASSISTANTS' | translate }}
                                </h2>
                                <p class="hdmui-font-small hdmui-m-0 hdmui-mt-1">
                                    {{ 'DC.JOIN-OR-TAKEOVER.DIALOG.ADD_ASSISTANTS.EXPLANATION' | translate }}
                                </p>
                                <h2 class="hdmui-font-small hdmui-font-bold hdmui-m-0 hdmui-mt-4">
                                    {{ 'DC.JOIN-OR-TAKEOVER.DIALOG.REMOVE_ASSISTANTS' | translate }}
                                </h2>
                                <p class="hdmui-font-small hdmui-m-0 hdmui-mt-1">
                                    {{ 'DC.JOIN-OR-TAKEOVER.DIALOG.REMOVE_ASSISTANTS.EXPLANATION' | translate }}
                                </p>
                            </data-terminal-help-button>
                        </div>
                        <div class="sign-in__cards hdmui-mt-4 hdmui-pb-4">
                            @for (machine of machinesWithAssistants; track machine) {
                                <data-terminal-manage-assistant-card
                                    [workstation]="machine"
                                    [availableEmployees]="getAvailableEmployees(machine)"
                                    (selectedEmployeesChange)="onSelectedEmployeesChange($event, machine.machineId)"
                                >
                                </data-terminal-manage-assistant-card>
                            }
                        </div>
                    </div>
                </mat-step>
            }
        </mat-stepper>
    </section>

    <mat-divider></mat-divider>

    <footer>
        <div>
            @if ((stepper?.steps?.length || 0) > 1) {
                <button mat-stroked-button (click)="onCancelClick()">
                    {{ '*.action.cancel' | translate }}
                </button>
            }
        </div>
        <div>
            <button
                mat-stroked-button
                matStepperPrevious
                (click)="(stepper?.steps?.length || 0) > 1 ? onPreviousClick() : onCancelClick()"
                [disabled]="(stepper?.steps?.length || 0) > 1 && stepper?.selectedIndex === 0"
            >
                {{ ((stepper?.steps?.length || 0) > 1 ? '*.action.previous' : '*.action.cancel') | translate }}
            </button>
            <button
                mat-flat-button
                (click)="stepper?.selectedIndex === (stepper?.steps?.length || 0) - 1 ? onFinishClick() : onNextClick()"
            >
                {{
                    (stepper?.selectedIndex === (stepper?.steps?.length || 0) - 1 ? '*.action.finish' : '*.action.next')
                        | translate
                }}
            </button>
        </div>
    </footer>
</div>
