import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, Subject, switchMap, tap } from 'rxjs';
import { RxState } from '@rx-angular/state';
import { UntilDestroy } from '@ngneat/until-destroy';

import {
    Activity,
    CaptureMode,
    LoginType,
    LoginTypeAndMachineId,
    Machine,
    Permission,
    Role,
    ROUTE_PATH,
    UserMachines,
} from '@data-terminal/shared-models';
import {
    DataTerminalSessionService,
    GLOBAL_RX_STATE,
    GlobalState,
    GlobalStateSelectors,
} from '@data-terminal/data-access';
import { PAGE_VISIBILITY_CHANGE, RequestMetadata } from '@data-terminal/utils';
import { GeneralActivitiesService } from '@data-terminal/feature-workstation-details';

import { SignInAdditionalDialogService } from '../../services/sign-in-additional-dialog/sign-in-additional-dialog.service';
import { UserMachinesService } from '../../../../../data-access/src/lib/user-machines';
import { SignInDialogClosedData } from '../../components/sign-in-machine-dialog/sign-in-machine-dialog.component';

@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class DashboardPresenter {
    public readonly userMachines$: Observable<RequestMetadata<UserMachines>> = this.globalState.select(
        GlobalStateSelectors.USER_MACHINES
    );
    public readonly otherActivities$: Observable<RequestMetadata<Activity[]>> = this.globalState.select(
        GlobalStateSelectors.OTHER_ACTIVITIES
    );

    private readonly _assignMachinesTriggerSubject = new Subject<SignInDialogClosedData>();
    public readonly assignMachinesTrigger$ = this._assignMachinesTriggerSubject.pipe(
        switchMap((signInDialogClosedData) => this.getSignInData(signInDialogClosedData)),
        switchMap((signInData) => this.userMachinesService.assignAndUpdateMachines(signInData)),
        tap((metadata) => this.redirectToOperationView(metadata))
    );

    constructor(
        @Inject(GLOBAL_RX_STATE) private readonly globalState: RxState<GlobalState>,
        @Inject(PAGE_VISIBILITY_CHANGE) public readonly visibilityChange$: Observable<boolean>,
        private readonly router: Router,
        private readonly dtSession: DataTerminalSessionService,
        private readonly userMachinesService: UserMachinesService,
        private readonly signInAdditionalDialogService: SignInAdditionalDialogService,
        private readonly generalActivitiesService: GeneralActivitiesService
    ) {}

    public triggerAssignMachines(signInDialogData: SignInDialogClosedData): void {
        this._assignMachinesTriggerSubject.next(signInDialogData);
        this.generalActivitiesService.triggerUpdateOtherActivities();
    }

    private getSignInData(signInDialogClosedData: SignInDialogClosedData): Observable<LoginTypeAndMachineId[]> {
        const { machines, withAssistants, availableAssistants } = signInDialogClosedData;
        const hasTakeOverPermissions = this.dtSession
            .getCurrentDataTerminalUser()
            .userPermissions.includes(Permission.TAKE_OVER);

        if (hasTakeOverPermissions) {
            const hasMachinesWithOperator = machines.some((machine) =>
                this.userMachinesService.hasMachineAnotherOperator(machine)
            );

            const hasTimeModeMachines = machines.some((machine) => machine.captureMode === CaptureMode.TIME);

            if (hasMachinesWithOperator || withAssistants || hasTimeModeMachines) {
                return this.signInAdditionalDialogService.getSignInData(machines, withAssistants, availableAssistants);
            }

            return of(this.assignToMachinesAs(Role.OPERATOR, machines));
        } else {
            return of(this.assignToMachinesAs(Role.ASSISTANT, machines));
        }
    }

    private redirectToOperationView(metadata: RequestMetadata<UserMachines>): void {
        if (metadata.data && metadata.data.assignedMachines.length === 1) {
            const page =
                metadata.data.assignedMachines[0].captureMode === CaptureMode.TIME
                    ? ROUTE_PATH.timeLogging
                    : ROUTE_PATH.operations;
            const path = `${this.router.url}/${metadata.data.assignedMachines[0].machineId}/${page}`;
            this.router.navigateByUrl(path);
        }
    }

    private assignToMachinesAs(role: Role, machines: Machine[]): LoginTypeAndMachineId[] {
        let loginType!: LoginType;

        switch (role) {
            case Role.OPERATOR:
                loginType = LoginType.TAKE_OVER;
                break;
            case Role.ASSISTANT:
                loginType = LoginType.JOIN;
                break;
        }

        return machines.map((machine) => ({
            machineId: machine.machineId,
            loginTyp: loginType,
        }));
    }
}
