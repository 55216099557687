<form [formGroup]="form">
    <mat-card appearance="outlined" class="sign-in-card">
        <div class="hdmui-p-3">
            <h1 class="hdmui-font-serif hdmui-m-0">
                {{ machine.machineName }}
            </h1>
            <h1 class="hdmui-font-bold hdmui-m-0">
                {{ machine.machineId }}
            </h1>
        </div>

        <mat-form-field>
            <mat-label> {{ '*.startTime' | translate }}</mat-label>
            <input type="time" matInput formControlName="time" />
            <mat-icon matIconSuffix svgIcon="hdmui:clock"></mat-icon>
            @if (form.get('time')?.hasError('maxStartTime')) {
                <mat-error>
                    {{ 'DC.SIGN_IN_MACHINE_DIALOG.START_TIMES.ERROR.FUTURE' | translate }}
                </mat-error>
            }
            @if (form.get('time')?.hasError('pattern') || form.get('time')?.hasError('required')) {
                <mat-error>
                    {{ 'DC.SIGN_IN_MACHINE_DIALOG.START_TIMES.ERROR.PATTERN' | translate }}
                </mat-error>
            }
        </mat-form-field>

        <p>
            {{ 'DC.SIGN_IN_MACHINE_DIALOG.START_TIMES.CARD.INFO' | translate }}
        </p>
    </mat-card>
</form>
