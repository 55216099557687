<mat-card appearance="outlined" class="manage-assistant-card hdmui-p-4">
    <div class="manage-assistant-card__header">
        <h1 class="hdmui-font-serif hdmui-m-0">
            {{ workstation.machineName }}
        </h1>
        <h1 class="hdmui-font-bold hdmui-m-0">
            {{ workstation.machineId }}
        </h1>
    </div>

    <form [formGroup]="form" class="hdmui-mt-4">
        <mat-form-field>
            <mat-label> {{ 'DC.MANAGE_ASSISTANTS.INPUT.NAME_EMAIL' | translate }}</mat-label>
            <input matInput formControlName="employeeId" [matAutocomplete]="auto" />
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="addEmployee()">
                @for (suggestion of employeeSuggestions$ | async; track suggestion) {
                    <mat-option [value]="suggestion.userId">
                        <hdmui-avatar
                            [fullName]="suggestion.userId"
                            [matTooltip]="suggestion.userId"
                            matTooltipPosition="above"
                            class="hdmui-avatar-small"
                        ></hdmui-avatar>
                        <div class="manage-assistant-card__employee--name">
                            <p class="hdmui-m-0">{{ suggestion.firstName }} {{ suggestion.familyName }}</p>
                            <p class="hdmui-m-0 hdmui-font-small">{{ suggestion.userId }}</p>
                        </div>
                    </mat-option>
                }
            </mat-autocomplete>
            @if (form.get('employeeId')?.hasError('unknownEmployee')) {
                <mat-error>{{ 'DC.MANAGE_ASSISTANTS.INPUT.NAME_EMAIL.UNKNOWN_EMPLOYEE' | translate }} </mat-error>
            }
            @if (form.get('employeeId')?.hasError('employeeAdded')) {
                <mat-error>{{ 'DC.MANAGE_ASSISTANTS.INPUT.NAME_EMAIL.EMPLOYEE_ADDED' | translate }} </mat-error>
            }
        </mat-form-field>
        <button
            mat-icon-button
            [disabled]="selectedEmployees().length >= employeesLimit || !form.valid || !form.get('employeeId')?.value"
            (click)="addEmployee()"
        >
            <mat-icon svgIcon="hdmui:plusCircled"></mat-icon>
        </button>
    </form>

    <mat-divider class="hdmui-my-4"></mat-divider>

    <div class="manage-assistant-card__content" [class.justify-center]="!selectedEmployees().length">
        @if (!selectedEmployees().length) {
            <div class="manage-assistant-card__content--empty">
                <mat-icon svgIcon="hdmui:personsGroupPeople"></mat-icon>
                <p class="hdmui-font-small">
                    {{ 'DC.MANAGE_ASSISTANTS.NO_SELECTED_ASSISTANTS_INFO' | translate }}
                </p>
            </div>
        }

        @for (employee of selectedEmployees(); track employee) {
            <div class="manage-assistant-card__employee">
                <div class="manage-assistant-card__employee--info">
                    <hdmui-avatar
                        [fullName]="employee.userId"
                        [matTooltip]="employee.userId"
                        matTooltipPosition="above"
                        class="hdmui-avatar-small"
                    ></hdmui-avatar>
                    <div class="manage-assistant-card__employee--name">
                        <p class="hdmui-m-0">{{ employee.firstName }} {{ employee.familyName }}</p>
                        <p class="hdmui-m-0 hdmui-font-small">{{ employee.userId }}</p>
                    </div>
                </div>
                <button mat-icon-button (click)="removeEmployee(employee.userId)">
                    <mat-icon svgIcon="hdmui:minusCircled"></mat-icon>
                </button>
            </div>
        }
    </div>

    <mat-divider class="hdmui-my-4"></mat-divider>

    <p class="manage-assistant-card__counter hdmui-m-0 hdmui-font-small">
        {{
            'DC.MANAGE_ASSISTANTS.NUMBER_OF_SELECTED_ASSISTANTS'
                | translate
                    : {
                          selected: selectedEmployees().length,
                          limit: employeesLimit,
                      }
        }}
    </p>
</mat-card>
