import { ChangeDetectionStrategy, Component, inject, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatRadioChange, MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatToolbarModule } from '@angular/material/toolbar';
import { CdkStepper } from '@angular/cdk/stepper';
import { MatStep, MatStepper, MatStepperModule } from '@angular/material/stepper';
import { MatIconModule } from '@angular/material/icon';

import {
    AvailableAssistants,
    AvailableAssistantsUser,
    CaptureMode,
    LoginType,
    LoginTypeAndMachineId,
    Machine,
} from '@data-terminal/shared-models';
import { HelpButtonComponent } from '@data-terminal/ui-presentational';

import { ManageAssistantCardComponent } from '../../../../../feature-dialogs/src/lib/components/manage-assistant-card/manage-assistant-card.component';
import { UserMachinesService } from '../../../../../data-access/src/lib/user-machines';
import { SignInTimeMachineCardComponent } from '../sign-in-time-machine-card/sign-in-time-machine-card.component';

type SelectableMachine = Machine & { loginType: LoginType; withAssistants?: boolean; timestamp?: number };

@Component({
    templateUrl: './sign-in-additional-dialog.component.html',
    styleUrls: ['./sign-in-additional-dialog.component.scss'],
    standalone: true,
    providers: [CdkStepper],
    imports: [
        FormsModule,
        TranslateModule,
        MatButtonModule,
        MatCardModule,
        MatDividerModule,
        MatRadioModule,
        MatCheckboxModule,
        ManageAssistantCardComponent,
        MatToolbarModule,
        MatStepperModule,
        MatIconModule,
        HelpButtonComponent,
        ReactiveFormsModule,
        SignInTimeMachineCardComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignInAdditionalDialogComponent {
    readonly #dialogRef = inject(MatDialogRef<SignInAdditionalDialogComponent>);
    readonly #dialogData: {
        selectedMachines: Machine[];
        withAssistants: boolean;
        availableAssistants: AvailableAssistants[];
    } = inject(MAT_DIALOG_DATA);
    readonly #userMachinesService = inject(UserMachinesService);

    @ViewChildren(SignInTimeMachineCardComponent)
    readonly signInTimeMachineCardComponents?: QueryList<SignInTimeMachineCardComponent>;

    @ViewChildren(MatStep)
    readonly steps?: QueryList<MatStep>;

    @ViewChild(MatStepper)
    readonly stepper?: MatStepper;

    @ViewChild('startTimesStep')
    readonly startTimesStep?: MatStep;

    @ViewChild('actionsStep')
    readonly actionsStep?: MatStep;

    @ViewChild('assistantsStep')
    readonly assistantsStep?: MatStep;

    readonly #allMachines: Machine[] = this.#dialogData.selectedMachines;
    public availableAssistants: AvailableAssistants[] = this.#dialogData.availableAssistants;

    public finalMachineList: LoginTypeAndMachineId[];
    public machinesWithOperator: SelectableMachine[];
    public machinesWithoutOperator: SelectableMachine[];
    public timeModeMachines: SelectableMachine[];

    public readonly LoginType = LoginType;

    constructor() {
        this.machinesWithOperator = this.#allMachines
            .filter((machine) => this.#userMachinesService.hasMachineAnotherOperator(machine))
            .map((m) => ({
                ...m,
                loginType: m.captureMode === CaptureMode.TIME ? LoginType.TAKE_OVER : LoginType.JOIN,
            }));
        this.machinesWithoutOperator = this.#allMachines
            .filter((machine) => !this.#userMachinesService.hasMachineAnotherOperator(machine))
            .map((m) => ({
                ...m,
                loginType: LoginType.TAKE_OVER,
                withAssistants: this.#dialogData.withAssistants,
            }));

        this.finalMachineList = this.createDefaultListOfMachineToAssign([
            ...this.machinesWithOperator,
            ...this.machinesWithoutOperator,
        ]);

        this.timeModeMachines = this.#allMachines
            .filter((machine) => machine.captureMode === CaptureMode.TIME)
            .map((m) => ({ ...m, loginType: LoginType.TAKE_OVER }));
    }

    public onCancelClick(): void {
        this.#dialogRef.close(false);
    }

    public onFinishClick(): void {
        if (!!this.startTimesStep && this.hasInvalidStartTimeInputs()) {
            return;
        }

        this.#dialogRef.close(this.finalMachineList);
    }

    public hasInvalidStartTimeInputs(): boolean {
        if (this.signInTimeMachineCardComponents?.length) {
            return this.signInTimeMachineCardComponents.some((c) => !c.form.valid);
        }
        return false;
    }

    public onPreviousClick(): void {
        this.stepper?.previous();
    }

    public onNextClick(): void {
        this.stepper?.next();
    }

    public onLoginTypeChange(change: MatRadioChange, machine: SelectableMachine): void {
        this.assignMachine(machine, change.value);

        if (change.value === LoginType.JOIN) {
            machine.withAssistants = false;

            this.finalMachineList = this.finalMachineList.map((m) => {
                if (m.machineId === machine.machineId) {
                    return { ...m, withAssistantIds: undefined };
                } else {
                    return m;
                }
            });
        }
    }

    public get machinesWithAssistants(): SelectableMachine[] {
        return [...this.machinesWithOperator, ...this.machinesWithoutOperator].filter(
            ({ withAssistants, captureMode }) => !!withAssistants && captureMode !== CaptureMode.TIME
        );
    }

    public getAvailableEmployees(machine: Machine): AvailableAssistantsUser[] {
        return this.availableAssistants.find(({ machineId }) => machineId === machine.machineId)?.users || [];
    }

    public onSelectedEmployeesChange(employees: AvailableAssistantsUser[], machineId: string): void {
        this.finalMachineList = this.finalMachineList.map((m) => {
            if (m.machineId === machineId) {
                return { ...m, withAssistantIds: employees.map(({ userId }) => userId) };
            } else {
                return m;
            }
        });
    }

    public onSignInTimestampChange(timestamp: number, machine: SelectableMachine): void {
        this.finalMachineList = this.finalMachineList.map((m) => {
            if (m.machineId === machine.machineId) {
                return { ...m, timestamp };
            } else {
                return m;
            }
        });
        machine.timestamp = timestamp;
    }

    private assignMachine(machine: SelectableMachine, loginType: LoginType): void {
        this.finalMachineList = this.finalMachineList.map((m) => {
            if (m.machineId === machine.machineId) {
                return { ...m, loginTyp: loginType };
            } else {
                return m;
            }
        });
        machine.loginType = loginType;
    }

    private createDefaultListOfMachineToAssign(machines: SelectableMachine[]): LoginTypeAndMachineId[] {
        return machines.map((machine) => ({
            machineId: machine.machineId,
            loginTyp: machine.loginType,
            withAssistantIds: undefined,
        }));
    }

    protected readonly CaptureMode = CaptureMode;
}
