import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SignInAdditionalDialogComponent } from '../../components/sign-in-additional-dialog/sign-in-additional-dialog.component';
import { filter, map, Observable } from 'rxjs';
import { AvailableAssistants, LoginTypeAndMachineId, Machine } from '@data-terminal/shared-models';

@Injectable({
    providedIn: 'root',
})
export class SignInAdditionalDialogService {
    constructor(private readonly dialog: MatDialog) {}

    public getSignInData(
        selectedMachines: Machine[],
        withAssistants: boolean,
        availableAssistants: AvailableAssistants[]
    ): Observable<LoginTypeAndMachineId[]> {
        const dialog: MatDialogRef<SignInAdditionalDialogComponent, LoginTypeAndMachineId[]> = this.dialog.open(
            SignInAdditionalDialogComponent,
            {
                minWidth: '95%',
                height: '90%',
                data: { selectedMachines, withAssistants, availableAssistants },
                disableClose: true,
            }
        );
        return dialog.afterClosed().pipe(
            filter((data) => !!data),
            map((data) => data as LoginTypeAndMachineId[])
        );
    }
}
